import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TermsAndConditionsPage = () => (
  <Layout>
    <SEO title="Yams Studio: Microsite Terms & Conditions" />
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 m-5">
        <p className="text-xl md:text-3xl leading-none">
          We have a few guidelines to help keep things in order.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          We’ll keep it short, sweet and straight to the point.
        </p>
      </div>
      <div className="w-full md:w-1/3 m-5">
        <h2 className="text-base md:text-lg leading-tight uppercase">
          Terms &amp; Conditions
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          We reserve the right to share work in progress and finished project
          assets on social media and in our portfolio.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          We reserve the right to refuse service to anyone for any reason.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          We reserve the right to cancel your booking.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          The design is final with the exception of minor revisions.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Our credit (“Site by Yams Studio”) cannot be modified, hidden or
          removed.
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          Payment
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          Once your booking is confirmed, you will receive an invoice for
          deposit. The deposit is 50% of the total cost. No refunds unless we
          cancel your booking.
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          The remaining balance is due upon completion and before the launch of
          your microsite.
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          The Barter System / COVID-19 Response
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          The terms outlined in <span className="uppercase">Payment</span> do
          not apply and our services will be provided free of charge.
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsAndConditionsPage
